const config = {
	title: process.env.REACT_APP_SITE_NAME,
	version: process.env.REACT_APP_VERSION,
	apiHost:
		process.env.NODE_ENV === 'production'
			? 'https://api.qa.oms.firstclose.com/oms'
			: process.env.REACT_APP_API_HOST,
};

export default config;
