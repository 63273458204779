import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
	useQuery,
	useMutation,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';

const queryClient = new QueryClient();

const onRedirectCallback = () => {
	window.history.replaceState({}, document.title, window.location.pathname);
};

const children = (
	<Auth0Provider
		domain='oms-dev.us.auth0.com'
		clientId='3fg7qNT9qH9ZyCUOVLLclchZ7WpkQLSn'
		authorizationParams={{
			redirect_uri: window.location.origin,
			organization: 'org_jIKfJP65r6emFE6G',
		}}
		cacheLocation='localstorage'
		onRedirectCallback={onRedirectCallback}

	>
		<AuthContextProvider>
			<ThemeContextProvider>
				<Router>
					<React.StrictMode>
						<QueryClientProvider client={queryClient}>
							<App />
							<ReactQueryDevtools initialIsOpen />
						</QueryClientProvider>
					</React.StrictMode>
				</Router>
			</ThemeContextProvider>
		</AuthContextProvider>
	</Auth0Provider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
