import { IButtonProps } from '@this/template/components/bootstrap/Button';
import useDarkMode from '@this/template/hooks/useDarkMode';
import useDeviceScreen from '@this/template/hooks/useDeviceScreen';
import Header, { HeaderRight } from '@this/template/layout/Header/Header';
import UserHeader from '@this/template/layout/User/UserHeader';

const MainHeader = () => {
	const { width } = useDeviceScreen();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	return (
		<Header>

			<HeaderRight>
				<div className='row g-3 align-items-center'>
					<div className='col-auto'>
						<UserHeader collapsible={false} />
					</div>
				</div>
			</HeaderRight>
		</Header>
	);
};

export default MainHeader;
