import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jwt-decode';
import axios from 'axios';
import _ from 'lodash';
import Profile from '@this/impl/api/models/profile';
import { useAuth0 } from '@auth0/auth0-react';
import config from '@this/impl/config';
import { toast } from 'react-toastify';
import { IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
	token: string | undefined;
	userData: Partial<IUserProps>;
	logout(): void;
	hasPrivilege(privileges: string[]): boolean | undefined;
	isLoggedIn(): boolean;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const {
		loginWithRedirect,
		logout,
		isAuthenticated,
		isLoading,
		getAccessTokenSilently,
		error: auth0Error,
	} = useAuth0();

	const [token, setToken] = useState<string | undefined>(undefined);
	const [userData, setUserData] = useState<Partial<IUserProps>>({});
	const params = new URLSearchParams(window.location.search);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const isLoggedIn = (): boolean => {
		return isAuthenticated;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const hasPrivilege = (privileges: string[]) => {
		return (
			userData?.privileges?.some((p) => privileges.includes(p)) || userData?.sudo === true
		);
	};

	useEffect(() => {
		if (isLoading || !isAuthenticated) return;

		const getTokenAndUserData = async () => {
			try {
				const accessToken = await getAccessTokenSilently({
					authorizationParams: {
						audience: 'https://oms.firstclose.com',
						scope: 'openid email',
					},
				});

				const authResponse = await axios.post(
					`${config.apiHost}/auth/auth0`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);

				setToken(authResponse.data.access_token);

				const payload: any = jwt(authResponse.data.access_token ?? '');

				const profilesPrivileges = _.flatMapDeep(
					payload.user?.profiles?.map((p: Profile) => p.privileges),
				);

				setUserData({
					id: payload.id ?? '',
					email: payload.email,
					username: payload.sub ?? '',
					name: payload.name,
					privileges: [...(payload.user?.privileges ?? []), ...profilesPrivileges],
					sudo: payload.user?.sudo ?? false,
				});

				axios.defaults.headers.common.Authorization = `Bearer ${authResponse.data.access_token}`;
			} catch (error: any) {
				console.error('Error fetching the access token:', error);

				// Log the error object to see its structure
				console.log('Error details:', {
					error: error.error,
					error_description: error.error_description,
					message: error.message,
				});

				// Adjust your condition based on the actual error properties
				if (error.error === 'login_required' || error.error === 'consent_required') {
					await loginWithRedirect();
				} else {
					console.error('An unexpected error occurred:', error);
				}
			}
		};

		getTokenAndUserData();
	}, [isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect]);

	const value = useMemo(
		() => ({
			logout,
			isLoggedIn,
			token,
			userData,
			hasPrivilege,
		}),
		[logout, isLoggedIn, token, userData, hasPrivilege],
	);

	if (isLoading) {
		return <>Loading...</>;
	}

	if (auth0Error) {
		return <div>Authentication Error: {auth0Error.message}</div>;
	}

	if (!isAuthenticated) {
		loginWithRedirect();
		return <>Redirecting to login...</>;
	}

	if (!token) {
		return <>Loading user data...</>;
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
