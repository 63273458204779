const productsMenu = {
	id: 'sampleCrudMenu',
	text: 'Products',
	icon: 'Dashboard',
	path: '/admin/products',
};

const ordersMenu = {
	id: 'ordersMenu',
	text: 'Orders',
	icon: 'Dashboard',
	path: '/admin/orders',
};


// eslint-disable-next-line import/prefer-default-export
export const adminMenu = {
	orders: ordersMenu,
	// pos: posConfigurationMenu,
	products: productsMenu,
};
