import React, { lazy } from 'react';
import AdminRoutes from './admin/content.routes';

const LOGIN = lazy(() => import('../pages/login/Login'));

const presentation = [
	{
		path: '/login',
		element: <LOGIN />,
	},
];

const contents = [...presentation, ...AdminRoutes];

export default contents;
