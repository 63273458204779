import React, { lazy } from 'react';

const ADMIN = {
	HEADER: lazy(() => import('@this/impl/pages/admin/header')),
};

const routes = [
	{
		path: '/admin/*',
		element: <ADMIN.HEADER />,
	},
];

export default routes;
